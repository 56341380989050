// types
/** @type {import('@sveltejs/kit').ParamMatcher} */

// learn more
// read more: https://kit.svelte.dev/docs/advanced-routing#matching

// match url param to valid news article slug
// throw 404 if not found
export function match(param: string): boolean {
	// if it is not alphanumeric and hyphens, return false
	const regex = /^[a-zA-Z0-9-]+$/;

	// if it is too long or too short, return false
	if (
		param.length < 2 ||
		param.length > 200 ||
		!regex.test(param) ||
		!param.includes("-")
	) {
		return false;
	}

	return true;
}
