// stores
import { get } from "svelte/store";

/** @type {import('@sveltejs/kit').ParamMatcher} */
// url matcher for terminals
// read more: https://kit.svelte.dev/docs/advanced-routing#matching

// match url param to valid news article slug
// throw 404 if not found
export function match(param) {
	// match alpha numeric and hyphens
	const regex = /^[a-zA-Z0-9-]+$/;

	// if param is not valid, return false
	if (param.length < 2 || param.length > 35 || !regex.test(param)) {
		return false;
	}

	// if param is not a valid url
	else if (param == "terms" || param == "privacy") {
		return true;
	}

	return false;
}
