// types
import type { ParamMatcher } from "@sveltejs/kit";

// match url param to valid news article slug
// throw 404 if not found
export const match = ((param: string) => {
	// match alpha numeric and hyphens
	const regex = /^[a-zA-Z0-9-]+$/;

	if (param.length < 3 || param.length > 60 || !regex.test(param)) {
		return false;
	}

	return true;
}) satisfies ParamMatcher;
